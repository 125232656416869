import { PortalAdminService } from './../../portal-admin/services/portal-admin.service';
// import { userCredentials } from './../../../../e2e/fixtures/config-data';
import { CookieStorage } from 'app/base/services/cookie-storage.service';
import { Injectable } from '@angular/core';

import { Observable ,  of } from 'rxjs';
import { UserService } from 'app/base/services/user.service';
import { LocalDataStoreService } from 'app/base/services/local-data-store.service';

@Injectable()
export class OrgListResolveGuardService  {
  constructor(
    private localDataStoreService: LocalDataStoreService,
    private portalAdminService: PortalAdminService
  ) {
  }

  resolve(): Observable<any> {
    if (this.localDataStoreService.orgList) {
      return of(this.localDataStoreService.orgList);
    }
    const request = { search: {} };
    return this.portalAdminService.fetchListOfOrg(request);
  }
}
