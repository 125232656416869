// import { userCredentials } from './../../../../e2e/fixtures/config-data';
import { CookieStorage } from 'app/base/services/cookie-storage.service';
import { Injectable } from '@angular/core';

import { Observable ,  of } from 'rxjs';
import { UserService } from 'app/base/services/user.service';
import { LocalDataStoreService } from 'app/base/services/local-data-store.service';
// import 'rxjs/add/operator/map';
import { map } from 'rxjs/operators';

@Injectable()
export class SolutionResolveGuardService  {
  constructor(
    private cookieStorage: CookieStorage,
    private userService: UserService,
    private localDataStoreService: LocalDataStoreService
  ) {
  }

  resolve(): Observable<any> {
    if (this.localDataStoreService.solutionList) {
      return of(this.localDataStoreService.solutionList);
    }
    const token = this.cookieStorage.getCookie('token');
    if (token) {
      return this.userService.getSolutions().pipe(map(res => {
        this.localDataStoreService.setSolutions(res.data);
        return res;
      }));
    }
  }
}
